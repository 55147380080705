.login-dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-header {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
}

.dropdown-arrow {
  font-size: 12px;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  transform: translateX(-60%);
  width: 250px;
  background: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 100;
}

.dropdown-menu::before {
  content: "";
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-60%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #ddd;
  z-index: 101;
}

.dropdown-section h4 {
  margin: 5px 0;
  font-size: 14px;
  color: #333;
}

.dropdown-section ul {
  list-style: none;
  padding: 0;
}

.dropdown-section ul li {
  margin: 5px 0;
  cursor: pointer;
}

.dropdown-section ul li span {
  text-decoration: none;
  color: #0073bb;
}

.dropdown-section ul li span:hover {
  text-decoration: underline;
}

.dropdown-actions {
  text-align: center;
  margin-top: 10px;
}

.dropdown-button {
  padding: 8px 15px;
  background-color: #f0c14b;
  border: 1px solid #a88734;
  color: #111;
  cursor: pointer;
  border-radius: 3px;
}

.dropdown-button:hover {
  background-color: #ddb347;
}
