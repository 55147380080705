.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px; /* Add some padding so the modal is not hugging the edges on very small screens */
}

.modal-content {
  background: #fff;
  border-radius: 8px;
  padding: 25px 30px;
  width: 100%;
  max-width: 400px;
  text-align: center;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  position: relative; /* allows for absolute positioning of close button if needed */
}

.modal-content h3 {
  margin-bottom: 20px;
  font-size: 1.4rem;
  font-weight: bold;
  color: #333;
}

.modal-content form input {
  width: 100%;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  box-sizing: border-box;
  transition: border-color 0.3s ease;
}

.modal-content form input:focus {
  border-color: #0073bb;
  outline: none;
}

.modal-actions {
  margin-top: 25px;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.modal-button {
  padding: 10px 20px;
  background-color: #0073bb;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.modal-button:hover {
  background-color: #005fa3;
}

.modal-button.cancel {
  background-color: #ccc;
  color: #333;
}

.modal-button.cancel:hover {
  background-color: #999;
}
