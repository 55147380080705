/* src/components/home/CategoryCard.css */
.category-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 150px;
  height: 200px;
  padding: 10px;
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.category-card-image {
  width: 100%;
  height: 100px;
  object-fit: cover;
  border-radius: 4px;
}

.category-card-name {
  margin-top: 10px;
  font-size: 14px;
  color: #333;
  font-weight: bold;
  text-align: center;
}
