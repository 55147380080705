/* src/components/dashboard/DashboardCard.css */
.dashboard-card {
  cursor: pointer;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd; /* Subtle grey border */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
}

.dashboard-card h3 {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.dashboard-card p {
  font-size: 1em;
  color: #666;
}


