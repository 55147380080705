/* src/components/admin/ProductManagement.css */
.product-management {
  padding: 20px;
  background: #f5f5f5;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.table-actions {
  display: flex;
  gap: 10px;
}
