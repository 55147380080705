.product-detail {
  font-family: Arial, sans-serif;
  padding: 20px;
  line-height: 1.6;
  color: #333;
}

.product-detail-header {
  text-align: left;
  margin-bottom: 20px;
  position: relative;
  z-index: 5;
}

.product-title {
  font-size: 1.8rem;
  margin-bottom: 5px;
}

.product-price {
  color: red;
  font-size: 1.5rem;
  font-weight: bold;
}

.divider {
  border: none;
  border-top: 1px solid #e1e1e1;
  margin: 20px 0;
}

.product-detail-body {
  display: flex;
  gap: 20px;
  align-items: flex-start; /* Align content at the top */
  overflow: visible; /* Ensure no clipping */
}

.product-image-section {
  flex: 1;
  text-align: center;
}

.product-main-image {
  max-width: 100%;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 10px;
}

.product-thumbnails {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.thumbnail {
  max-width: 60px;
  max-height: 60px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

.product-info-section {
  flex: 2;
  text-align: left;
  position: relative;
  overflow: visible; /* Prevent content clipping */
}

.product-description {
  margin: 10px 0;
  line-height: 1.5;
  overflow: visible; /* Ensure full visibility */
  max-height: none; /* Remove any height restriction */
}

.see-more-button {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-weight: bold;
  padding: 0;
  font-size: 1rem;
  margin-top: 10px;
}

.see-more-button:hover {
  text-decoration: underline;
}

.product-detail-footer {
  text-align: center;
}

.whatsapp-button {
  display: inline-block;
  background-color: #25d366;
  color: white;
  font-size: 1rem;
  padding: 10px 20px;
  border-radius: 25px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.whatsapp-button:hover {
  background-color: #20b358;
}

.product-detail-error,
.product-detail-not-found {
  color: red;
  font-size: 1.2rem;
  text-align: center;
}

.product-detail-loading {
  text-align: center;
  font-size: 1.2rem;
}
