.account-page {
  padding: 20px;
  max-width: 80%; /* Ensure full width on smaller screens */
  margin: 0 auto;
}

.account-page h1 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.8em; /* Slightly smaller for better fit */
  color: #333;
}

.account-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Responsive columns */
  gap: 16px; /* Adjust spacing between cards */
  justify-items: center; /* Center cards */
}
