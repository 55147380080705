.product-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 300px;
  margin: auto;
  transition: transform 0.2s, height 0.3s ease-in-out;
  border: 1px solid #d3d3d3;
}

.product-card:hover {
  transform: translateY(-5px);
  border-color: #b0b0b0;
}

.product-card-image-container {
  width: 100%;
  height: 220px; /* Fixed height to standardize display */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 8px; /* Optional for aesthetic appeal */
}

.product-card-main-image {
  width: auto; /* Maintain aspect ratio */
  height: 100%; /* Ensure full height is used */
  object-fit: contain; /* Ensure the image fits within the container */
}

.product-card-thumbnail-gallery {
  display: flex;
  gap: 5px;
  justify-content: center;
  margin: 10px 0;
}

.product-card-thumbnail-image {
  width: 60px; /* Adjusted size for better display */
  height: 60px;
  border-radius: 4px;
  cursor: pointer;
  object-fit: cover; /* Ensure thumbnails fill their container */
}

.product-card-details {
  text-align: center;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-card-description-container {
  position: relative;
  margin-bottom: 20px;
  width: 100%;
}

.product-card-description {
  max-height: 60px; /* Initial height when collapsed */
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5;
  transition: max-height 0.3s ease-in-out;
  margin-bottom: 8px;
}

.product-card-description.expanded {
  max-height: 1000px; /* Large enough to display full content */
}

.product-card-see-more {
  display: inline-block;
  margin-top: 5px;
  font-size: 0.9em;
  color: #0073e6;
  cursor: pointer;
}

.product-card-see-more:hover {
  text-decoration: underline;
}

.product-card-price {
  font-size: 1.5rem;
  font-weight: bold;
  color: red; /* Bold red price text */
  margin: 10px 0;
}

/* Add to Cart Button */
.product-card-add-to-cart-button {
  width: 80%;
  padding: 10px;
  background-color: #ffa41b; /* Amazon orange */
  color: white;
  font-weight: bold;
  border: none;
  border-radius: 25px;
  margin-bottom: 10px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease;
}

.product-card-add-to-cart-button:hover {
  background-color: #ff8c00; /* Slightly darker orange */
}

.product-card-whatsapp-button {
  margin-top: 10px; /* Adjusted for spacing between buttons */
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  padding: 10px;
  background-color: #25d366; /* WhatsApp green */
  color: white;
  font-weight: bold;
  text-decoration: none;
  border-radius: 25px;
  transition: background-color 0.3s ease;
}

.product-card-whatsapp-button:hover {
  background-color: #20b358;
}

.product-card-whatsapp-icon {
  height: 20px;
  margin-right: 10px;
}
