.header-container {
  width: 100%; /* Ensure the header spans the full width */
  box-sizing: border-box;
  margin: 0 auto 20px; /* Center the header with some spacing below */
  padding: 0 20px; /* Optional padding for content alignment */
}

.address-book {
  max-width: 1200px; /* Centralize the content below the header */
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.address-book h2 {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.address-book .subtitle {
  font-size: 1rem;
  color: #666;
  margin-bottom: 20px;
}

.address-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.dashboard-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  text-align: left;
  width: 100%;
  max-width: 500px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.dashboard-card:hover {
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.add-address-icon {
  font-size: 24px;
  color: #0073bb;
  margin-right: 10px;
  vertical-align: middle;
}

.delete-button {
    background: none; /* Removes default background */
    border: none; /* Removes border */
    padding: 0; /* Removes extra padding */
    margin-left: 10px; /* Adds spacing between the text and icon */
    cursor: pointer;
    font-size: 1rem; /* Ensures the icon is appropriately sized */
    color: #333; /* Matches the color of the card content */
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.delete-button:hover {
    color: #d9534f; /* Optional: Change color on hover for visual feedback */
    transform: scale(1.1); /* Optional: Slightly enlarge on hover */
}
