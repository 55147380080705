.cart-page {
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

h1 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.cart-empty {
  text-align: center;
}

.cart-empty-image {
  width: 200px;
  margin: 20px 0;
}

.cart-empty p {
  font-size: 1.2rem;
  color: #555;
}

.cart-items {
  margin-bottom: 20px;
}

.cart-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  border-bottom: 1px solid #ddd;
}

.cart-item:last-child {
  border-bottom: none;
}

.cart-item-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 4px;
}

.cart-item-details {
  flex-grow: 1;
  margin-left: 20px;
}

.cart-item-details h3 {
  font-size: 1rem;
  margin-bottom: 5px;
}

.cart-item-details p {
  color: #333;
  margin-bottom: 10px;
}

.cart-item-quantity {
  display: flex;
  align-items: center;
}

.cart-item-quantity label {
  margin-right: 10px;
}

.cart-item-quantity input {
  width: 60px;
  padding: 5px;
  font-size: 1rem;
  text-align: center;
}

.cart-summary {
  border-top: 2px solid #ddd;
  padding-top: 20px;
}

.cart-summary h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.cart-summary p {
  font-size: 1rem;
  margin-bottom: 10px;
}

.btn {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
}

.btn-primary {
  background-color: #007bff;
  color: #fff;
}

.btn-success {
  background-color: #28a745;
  color: #fff;
}

.btn-danger {
  background-color: #dc3545;
  color: #fff;
}

.btn:hover {
  opacity: 0.9;
}
