/* General Styles for HomePage */
.home-page {
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  color: #333;
}

/* Header Styling */
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #ffffff;
  border-bottom: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header-logo img {
  height: 50px;
}

.search-bar {
  display: flex;
  align-items: center;
  gap: 8px;
}

.search-bar input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.search-bar button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}

.search-bar button:hover {
  background-color: #0056b3;
}

/* Banner Carousel Styling */
.banner-carousel {
  display: flex;
  overflow-x: auto;
  gap: 15px;
  padding: 20px;
  background-color: #f5f5f5;
}

.banner-carousel img {
  flex: 0 0 auto;
  height: 300px;
  width: auto;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Category List Styling */
.category-list {
  display: flex;
  gap: 20px;
  padding: 20px;
  justify-content: center;
  background-color: #ffffff;
  flex-wrap: wrap;
}

.category-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 200px;
  height: 250px;
  padding: 15px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  text-align: center;
}

.category-card img {
  height: 120px;
  width: 120px;
  border-radius: 4px;
  object-fit: cover;
  margin-bottom: 10px;
}

.category-card p {
  font-size: 16px;
  color: #333;
  font-weight: bold;
}

.category-card:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px); /* Subtle elevation effect */
}

/* Footer Styling */
.footer {
  padding: 20px;
  background-color: #ffffff;
  text-align: center;
  border-top: 1px solid #ddd;
  font-size: 14px;
  color: #777;
}

.footer a {
  color: #007bff;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}
