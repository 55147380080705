.dashboard {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto; /* Center the dashboard content */
}

.dashboard h1 {
  text-align: center;
  margin-bottom: 40px;
  font-size: 2rem;
  font-weight: bold;
}

.dashboard-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px 30px; /* Add a small horizontal and vertical gap */
}

.dashboard-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.dashboard-card h3 {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.dashboard-card p {
  font-size: 0.9rem;
  color: #555;
  margin-bottom: 0; /* Remove extra bottom margin */
}

/* Mobile-specific styling */
@media (max-width: 768px) {
  .dashboard h1 {
    font-size: 1.5rem;
  }

  .dashboard-card {
    padding: 15px;
  }

  .dashboard-card h3 {
    font-size: 1rem;
  }

  .dashboard-card p {
    font-size: 0.85rem;
  }
}
