/* Root container similar to .address-book */
.shipping-selection-page {
  max-width: 1200px; /* Centralize the content below the header */
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

/* Main heading similar in style to address-book h2 */
.shipping-selection-page h1 {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

/* For messages when no addresses are available */
.no-address-message {
  font-size: 1rem;
  color: #666;
  margin-top: 20px;
}

/* Container for the address radio options */
.address-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 30px;
}

/* Each address option styled similarly to the dashboard-card */
.address-option {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 500px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px 20px;
  text-align: left;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  cursor: pointer;
}

.address-option:hover {
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
}

.address-option input[type="radio"] {
  margin-right: 10px;
  transform: scale(1.2);
  cursor: pointer;
}

.address-details {
  font-size: 1rem;
  color: #333;
}

/* Proceed button styling, consistent with .modal-button or .btn */
.proceed-button {
  margin-top: 30px;
  padding: 10px 20px;
  background-color: #0073bb;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.proceed-button:hover {
  background-color: #005fa3;
}

/* Optional loading spinner styling */
.spinner {
  margin: 20px auto;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #0073bb;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 0.8s linear infinite;
}

/* Spinner animation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
