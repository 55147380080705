/* Product List Grid Container */
.product-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); /* Responsive grid with min card width */
    gap: 40px; /* Consistent spacing between cards */
    max-width: 1200px; /* Limit max width for large screens */
    margin: 0 auto; /* Center the grid container */
    padding: 40px 20px; /* Add padding for spacing */
}


/* Pagination Styles */
.pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin: 20px 0; /* Space above and below pagination */
}

.pagination-button {
    padding: 8px 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #f7f7f7;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.2s, color 0.2s;
}

.pagination-button:disabled {
    cursor: not-allowed;
    color: #ccc;
    background-color: #f7f7f7;
}

.pagination-button:hover:not(:disabled) {
    background-color: #e7e7e7;
}

.pagination-page {
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.2s, color 0.2s;
}

.pagination-page.active {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
    cursor: default;
}

.pagination-page:hover:not(.active) {
    background-color: #f0f0f0;
}

.pagination-ellipsis {
    padding: 8px 12px;
    font-size: 14px;
    color: #666;
}

/* Responsive Design for Small Screens */
@media (max-width: 768px) {
    .product-list {
        grid-template-columns: 1fr; /* Single column layout */
        gap: 24px; /* Smaller gap between cards */
    }
}


