.banner-carousel {
  display: flex;
  overflow-x: auto;
  gap: 10px;
  padding: 20px;
}

.banner-carousel img {
  height: 300px;
  width: auto;
  border-radius: 8px;
}
