.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px; /* Reduced padding for a tighter layout */
  background-color: #f8f9fa; /* Light gray for background */
  border-bottom: 1px solid #ddd;
  flex-wrap: nowrap; /* Prevent wrapping unless necessary */
}

.header-logo img {
  height: 40px; /* Slightly smaller logo for compact layout */
  margin-right: 10px; /* Spacing between logo and search bar */
}

.header .search-bar {
  flex-grow: 1; /* Allow search bar to take up remaining space */
  max-width: 60%; /* Limit maximum width */
  margin-right: 15px; /* Add subtle spacing between search bar and cart icon */
}

.header .cart-icon {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: auto; /* Push the cart icon to the right */
  margin-right: 10px; /* Add a bit of spacing for balance */
  cursor: pointer;
}

.header .cart-icon .cart-count {
  position: absolute;
  top: -8px; /* Keep the badge positioned above */
  right: -8px; /* Align the badge on top-right */
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 0.8rem;
  font-weight: bold;
  text-align: center;
  min-width: 18px;
  height: 18px;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cart-icon svg {
  font-size: 1.5rem; /* Maintain a proportional cart icon size */
  color: #333; /* Match overall color theme */
}

@media (max-width: 768px) {
  .header {
    flex-wrap: wrap; /* Allow wrapping on smaller screens */
    justify-content: center; /* Center align items */
  }

  .header .search-bar {
    max-width: 100%; /* Full width for smaller screens */
    margin: 10px 0; /* Add vertical spacing */
  }

  .header .cart-icon {
    margin: 0; /* Reset margin for mobile */
  }
}
