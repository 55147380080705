.guest-checkout-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.guest-checkout-page h1 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.guest-checkout-page .subtitle {
  font-size: 1.1rem;
  color: #555;
  margin-bottom: 30px;
}

.guest-checkout-form {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Ensures two-column layout */
  gap: 20px;
  justify-items: stretch; /* Align items to take full width */
  margin-bottom: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-group.full-width {
  grid-column: span 2; /* Fields like email take the full row */
}

.form-group label {
  margin-bottom: 5px;
  font-weight: bold;
  text-align: left;
}

.form-group input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.btn.proceed-button {
  padding: 12px 20px;
  background-color: #0073bb;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  grid-column: span 2; /* Button takes the full row */
  margin-top: 20px;
}

.btn.proceed-button:hover {
  background-color: #005fa3;
}
