.order-confirmation {
  font-family: Arial, sans-serif;
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  background-color: #fff;
}

.confirmation-header {
  text-align: center;
  margin-bottom: 20px;
}

.confirmation-header .checkmark {
  font-size: 3em;
  color: #4caf50;
}

.confirmation-header h1 {
  margin: 10px 0;
  font-size: 1.8em;
  color: #333;
}

.order-summary, .receipt-section, .order-details, .shipping-details {
  margin: 20px 0;
}

h2 {
  font-size: 1.4em;
  margin-bottom: 10px;
  color: #333;
}

p {
  margin: 5px 0;
  font-size: 1em;
  color: #666;
}

.receipt-button {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
}

.receipt-button:hover {
  background-color: #0056b3;
}

.actions {
  text-align: center;
  margin-top: 20px;
}

.action-button {
  padding: 10px 20px;
  background-color: #f0c14b;
  border: 1px solid #a88734;
  border-radius: 4px;
  color: #111;
  cursor: pointer;
}

.action-button:hover {
  background-color: #ddb347;
}
