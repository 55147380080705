.cookie-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
}

.cookie-banner p {
  margin: 0;
  font-size: 14px;
}

.cookie-banner button {
  background-color: #0073e6;
  color: #fff;
  border: none;
  padding: 8px 12px;
  margin-left: 10px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
}

.cookie-banner button:hover {
  background-color: #005bb5;
}
